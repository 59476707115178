import { ErrorBoundary } from 'react-error-boundary';

import type { SortGame } from '../../../game/sort/game.ts';
import pastIcon from '../../assets/past-icon.svg';
import { toTheDay } from '../../lib/locale-date-strings.ts';
import { ClientEntry } from '../components/ClientEntry.tsx';
import { HorizontalLine } from '../components/design/HorizontalLine.tsx';
import { SortGamePlayer } from '../components/game/sort/SortGamePlayer.tsx';

export const Home = ({
  game,
  showArchiveBanner,
}: {
  game: SortGame;
  showArchiveBanner?: boolean;
}) => {
  return (
    <ClientEntry>
      <ErrorBoundary fallback={null}>
        {showArchiveBanner && <SaturdayAlert date={game.publishedAt} />}
      </ErrorBoundary>

      <SortGamePlayer game={game} />
    </ClientEntry>
  );
};

const SaturdayAlert = ({ date }: { date: Date }) => {
  return (
    <div className="mx-auto mb-0 w-full max-w-[40rem] px-2">
      <details className="group rounded bg-slate-800 px-4 py-2">
        <summary className="hide-summary-marker flex cursor-pointer list-none items-center justify-start gap-4 after:ml-auto after:content-['▶'] after:group-open:content-['▼']">
          <div className="flex gap-1 rounded bg-white bg-opacity-10 px-2 py-1 text-base font-normal">
            <img src={pastIcon.src} className="w-3" /> From the archives
          </div>
          <div className="text-base font-bold text-white">
            &nbsp;{toTheDay(date)}
          </div>
        </summary>
        <div className="mx-auto mt-0 w-11/12 py-1 text-base text-slate-300">
          <HorizontalLine />
          We'll be back on Monday with a new game! In the meantime, enjoy this
          game from the Disorderly{' '}
          <a className="font-semibold underline" href="/archive">
            archive
          </a>
          !
        </div>
      </details>
    </div>
  );
};
